<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="pt-4 pb-4 row w-100 justify-content-center align-items-center align-self-center d-grid">
  <app-avatar formControlName="avatar"></app-avatar>
  </div>
    <div class="row w-100">
    <div class="col-3 mb-3">
      <div class="col">
        <span class="field-description">
          {{ 'accountSettings.account.entries.username' | translate }}
        </span>
          <input
             type="text"
             class="mx-auto form-control custom-width custom-height"
             [placeholder]="userInfo?.username || PLACEHOLDER"
             name="username"
             formControlName="username"
             matInput
          >
      </div>
    </div>
    <div class="col-3 mb-3">
      <div class="col">
        <span class="field-description">
          {{ 'accountSettings.account.entries.firstname' | translate }}
        </span>
          <input
             type="text"
             class="mx-auto form-control custom-width custom-height"
             [placeholder]="userInfo?.firstname || PLACEHOLDER"
             name="firstname"
             formControlName="firstname"
             matInput
          >
      </div>
    </div>
    <div class="col-3 mb-3">
      <div class="col">
        <span class="field-description">
          {{ 'accountSettings.account.entries.lastname' | translate }}
        </span>
          <input
             type="text"
             class="mx-auto form-control custom-width custom-height"
             [placeholder]="userInfo?.lastname || PLACEHOLDER"
             name="lastname"
             formControlName="lastname"
             matInput
          >
      </div>
    </div>
      <div class="col-3 mb-3">
        <div class="col">
          <span class="field-description">
            {{ 'accountSettings.account.entries.email' | translate }}
          </span>
            <input
               type="text"
               class="mx-auto form-control custom-width custom-height"
               [placeholder]="userInfo?.email || PLACEHOLDER"
               name="email"
               formControlName="email"
               matInput
            >
        </div>
      </div>
  </div>

  <div class="row w-100">
    <div class="col-3 mb-3">
      <div class="col">
        <span class="field-description"><i class="fa-brands fa-spotify"></i></span>
          <input
             type="text"
             class="mx-auto form-control custom-width custom-height"
             [placeholder]="PLACEHOLDER"
             name="spotify"
             formControlName="spotify"
             matInput
          >
      </div>
    </div>
    <div class="col-3 mb-3">
      <div class="col">
        <span class="field-description"><i class="fa-brands fa-instagram"></i></span>
          <input
             type="text"
             class="mx-auto form-control custom-width custom-height"
             [placeholder]="PLACEHOLDER"
             name="instagram"
             formControlName="instagram"
             matInput
          >
      </div>
    </div>
    <div class="col-3 mb-3">
      <div class="col">
        <span class="field-description"><i class="fa-brands fa-soundcloud"></i></span>
          <input
             type="text"
             class="mx-auto form-control custom-width custom-height"
             [placeholder]="PLACEHOLDER"
             name="soundcloud"
             formControlName="soundcloud"
             matInput
          >
      </div>
    </div>
      <div class="col-3 mb-3">
        <div class="col">
          <span class="field-description">
            {{ 'accountSettings.account.entries.beatstars' | translate }}
          </span>
            <input
               type="text"
               class="mx-auto form-control custom-width custom-height"
               [placeholder]="PLACEHOLDER"
               name="beatstars"
               formControlName="beatstars"
               matInput
            >
        </div>
      </div>
  </div>

    <div class="mt-3 row col-12 mb-3">
    <div class="col">
      <span class="field-description">
        {{ 'accountSettings.account.entries.description' | translate }}
      </span>
      <label for="description"></label>
      <textarea
        [placeholder]="PLACEHOLDER"
         name="description"
         formControlName="description"
         matInput
        class="form-control"
        id="description"
        rows="4"></textarea>
    </div>
  </div>

  <div class="mt-3 row-cols-1 me-3">
    <button
      class="btn-helper btn btn-dark btn-disable-smooth btn-block custom-width custom-height mw-100"
      mat-flat-button
      type="submit"
      [disabled]="shouldDisableButton()"
        >
      {{ 'accountSettings.account.entries.saveBtn' | translate }}
    </button>
  </div>

</form>

