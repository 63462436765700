<mat-dialog-content class="p-4 pt-0">
  <div class="w-100 text-center">

    <div class="py-1">
      <h2 class="pb-0 user-select-none" mat-dialog-title>
        {{ 'newProjectModal.header' | translate }}
      </h2>
    </div>

    <div class="row w-100 mx-auto">
      <div class="col-12 mb-2">
        <input [(ngModel)]="projectName"
               matInput
               (keyup)="projectName = projectName?.trimStart() || ''"
               (focusout)="projectName = projectNameFormatted"
               type="text"
               class="form-control w-100"
               placeholder="* {{ 'newProjectModal.placeholder.projectName' | translate }}">
      </div>

      <div class="col-12 row w-100 mx-auto">
        <div class="col-6 col-lg-3 p-2 ps-0">
          <input [(ngModel)]="songBPM"
                 matInput
                 type="number"
                 class="form-control"
                 [placeholder]="'newProjectModal.placeholder.bpm' | translate">
        </div>

        <div class="col-6 col-lg-3 p-2 pe-0 pe-lg-2">
          <select class="form-control" [(ngModel)]="tone">
            <option value="" selected>{{ 'newProjectModal.placeholder.tone' | translate }}</option>
            <option *ngFor="let toneOption of KEY_TONE_OPTIONS" [value]="toneOption">{{ toneOption }}</option>
          </select>
        </div>

        <div class="col-6 col-lg-3 p-2 ps-0 ps-lg-2">
          <select class="form-control" [(ngModel)]="sharpFlat">
            <option value="" selected>{{ 'newProjectModal.placeholder.sharpFlat' | translate }}</option>
            <option *ngFor="let sharpFlatOption of KEY_SHARP_FLAT_OPTIONS" [value]="sharpFlatOption">{{ sharpFlatOption }}</option>
          </select>
        </div>

        <div class="col-6 col-lg-3 p-2 pe-0">
          <select class="form-control" [(ngModel)]="vibe">
            <option value="" selected>{{ 'newProjectModal.placeholder.vibe' | translate }}</option>
            <option *ngFor="let vibeOption of KEY_VIBE_OPTIONS" [value]="vibeOption">{{ vibeOption }}</option>
          </select>
        </div>
      </div>

      <div class="col-12 mt-4">
        <h4 class="ps-1 mb-2 text-dark user-select-none">
          {{ 'newProjectModal.uploadFile' | translate }}
        </h4>
        <input #audioFileInput
               type="file"
               accept=".wav"
               class="form-control">
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="p-4 pt-3">
  <div class="row w-100 mx-auto mb-1">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <button mat-button
              class="btn bg-white text-black border border-1 border-black"
              [mat-dialog-close]="true"
      >{{ 'newProjectModal.nav.cancel' | translate }}
      </button>
      <button mat-button
              class="btn bg-white text-black border border-1 border-black"
              (click)="submit().then()"
              [disabled]="!filled"
      >{{ 'newProjectModal.nav.submit' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-actions>