<div *ngIf="userInfoService.lastUserInfo" class="user-select-none small px-3 text-black-50">
  <div>{{ userInfoService.lastUserInfo!.email }}</div>
  <div>
    <span class="text-dark">{{ userInfoService.lastUserInfo!.firstname }} {{ userInfoService.lastUserInfo!.lastname }}</span>
    <span class="mx-1">|</span>
    <span class="text-dark text-opacity-75">{{ userInfoService.lastUserInfo!.username }}</span>
  </div>
  <hr class="my-2">
</div>
<button class="dropdown-item" (click)="userSettings()">{{ 'userDropdownContent.link.account' | translate }}</button>
<button class="dropdown-item logout" (click)="logout()">{{ 'userDropdownContent.link.logout' | translate }}</button>