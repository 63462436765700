<div class="navbar-collapse justify-content-end" id="navbarNav" *ngIf="isSessionActive()">
    <div class="navbar-nav flex-row justify-content-end gap-4">
        <ul ngbDropdown #notificationDropdown="ngbDropdown" class="notification-dropdown mb-0 ps-0"
            (touchend)="notificationDropdown.open()"
            (mouseover)="notificationDropdown.open()"
            (mouseleave)="notificationDropdown.close()"
        >
            <li class="nav-item" ngbDropdownItem>
                <button class="nav-link p-0 d-flex align-items-center">
                    <i class="fa fa-bell notification-icon"></i>
                </button>
                <app-badge
                        [content]="notificationService.unreadNotificationCount === undefined ? '0' : notificationService.unreadNotificationCount.toString()"
                        [hideOn0]=true
                        [style]="'top:0;right:-6px'"
                ></app-badge>
            </li>
            <li ngbDropdownMenu aria-labelledby="notificationDropdown" class="py-0 overflow-hidden">
                <app-notification-dropdown-content></app-notification-dropdown-content>
            </li>
        </ul>
        <ul ngbDropdown #userDropdown="ngbDropdown" class="mb-0 ps-0"
            (touchend)="userDropdown.open()"
            (mouseover)="userDropdown.open()"
            (mouseleave)="userDropdown.close()"
        >
            <li class="nav-item" ngbDropdownItem>
                <button class="nav-link p-0">
                    <img src="/assets/user.png" class="user-icon h-100" alt="user icon">
                </button>
            </li>
            <li ngbDropdownMenu aria-labelledby="userDropdown">
                <app-user-dropdown-content></app-user-dropdown-content>
            </li>
        </ul>
    </div>
</div>