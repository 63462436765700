import { HttpEventType } from '@angular/common/http';
import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnChanges,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { MarkerListMarkerClick as MarkerListMarkerClickInterface } from '../../../interfaces/action/markerListMarkerClick.interface';
import { MarkerListAction as MarkerListActionInterface } from '../../../interfaces/markerListAction.interface';
import { MarkerListMarker as MarkerListMarkerInterface } from '../../../interfaces/markerListMarker.interface';
import { ProjectChecklistEntryMarker as ProjectChecklistEntryMarkerInterface } from '../../../interfaces/projectChecklistEntryMarker.interface';
import { ProjectVersion as ProjectVersionInterface } from '../../../interfaces/projectVersion.interface';
import { FileUploadService } from '../../../services/file-upload.service';
import { ProjectService } from '../../../services/project.service';
import { TopBannerService } from '../../../services/top-banner.service';
import { TranslationService } from '../../../services/translation.service';
import { WaveformPlayerMarkerService } from '../../../services/waveform-player-marker.service';
import { MarkerChipListComponent } from '../../marker-chip/marker-chip-list/marker-chip-list.component';
import { WaveformPlayerComponent } from '../waveform-player.component';

@Component({
	selector: 'app-waveform-player-controls-large',
	templateUrl: './waveform-player-controls-large.component.html',
	styleUrls: ['./waveform-player-controls-large.component.scss'],
})
export class WaveformPlayerControlsLargeComponent implements OnInit, OnChanges {
	private readonly FILE_UPLOAD_ACCEPT = '.wav';

	protected readonly MARKER_LIST_ACTIONS: MarkerListActionInterface[] = [
		{
			name: 'DELETE',
			content: `<i class="fa fa-trash"></i>`,
			class: 'hover-text-danger',
		},
		{
			name: 'COLOR',
			content: '<i class="fa fa-palette"></i>',
			type: 'COLOR',
			global: false,
		},
		{
			name: 'ADD_TO_CHECKLIST',
			content: `<i class="fa fa-arrow-right"></i><i class="fa fa-check-square fw-light"></i>`,
			class: 'text-nowrap',
		},
	];

	@Input() project_id!: number;
	@Input() projectVersion?: ProjectVersionInterface;
	@Input() lastVersion?: ProjectVersionInterface;
	@Input() playerClass?: string;

	@Input() staticMarker: ProjectChecklistEntryMarkerInterface[] = [];

	@Input() controls: boolean = true;

	@Input() projectLoading: boolean = true;

	@Output() readonly play$ = new EventEmitter<void>();
	@Output() readonly addMarkerToChecklist$ = new EventEmitter<MarkerListMarkerInterface[]>();

	@ViewChild(WaveformPlayerComponent, { static: true }) waveformPlayer!: WaveformPlayerComponent;
	@ViewChildren('markerButton') protected markerButtons!: QueryList<ElementRef<HTMLElement>>;
	@ViewChild(MarkerChipListComponent, { static: true }) protected markerChipListComponent!: MarkerChipListComponent;
	@ViewChild('markerTextInput') protected markerTextInput?: ElementRef<HTMLInputElement>;

	protected get addMarkerButtonEnabled(): boolean {
		return !!(this.waveformPlayer?.peaks && this.projectVersion);
	}

	constructor(
		private readonly toastr: ToastrService,
		protected readonly projectService: ProjectService,
		protected readonly waveformPlayerMarkerService: WaveformPlayerMarkerService,
		private readonly topBannerService: TopBannerService,
		private readonly translationService: TranslationService,
		private readonly fileUploadService: FileUploadService,
	) {}

	ngOnInit() {
		this.waveformPlayerMarkerService.change$.subscribe(() => {
			this.updateMarker();
		});
		this.waveformPlayer.peaksReady$.subscribe(() => {
			this.updateMarker();
		});
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['projectVersion']) {
			this.waveformPlayer.connectToGlobalAudioPlayer(false).then(() => {
				this.waveformPlayer!.updateGlobalAudioPlayerVisibility();
			});
			this.updateMarker();
		}
		if (changes['staticMarker']) this.updateMarker();
	}

	get showNoAudioFileUploadedOverlay() {
		return (
			!this.projectLoading &&
			this.projectVersion &&
			this.projectVersion.versionNumber === this.lastVersion?.versionNumber &&
			!this.waveformPlayer.peaks &&
			!this.projectVersion.files.some((file) => file.type === 'wav')
		);
	}

	uploadAudioFileClick() {
		this.fileUploadService.selectFile({ multiple: false, accept: this.FILE_UPLOAD_ACCEPT }).subscribe(async (files) => {
			if (files?.length) {
				const banner = this.topBannerService.addBanner({
					icon: 'file-arrow-up',
					title: this.translationService.getTranslation('_topBanner.uploadProjectAudioFile.uploading.title'),
					text: '',
					progress: 0,
					onDoneData: {
						type: 'project-file-upload',
						data: this.project_id,
					},
				});

				this.projectLoading = true;

				await new Promise<void>((resolve) => {
					this.fileUploadService
						.deleteAudioFile(this.project_id, this.projectVersion!.versionNumber)
						.subscribe(() => resolve());
				});

				const audioFile = files[0];
				this.fileUploadService
					.uploadAudioFile(this.project_id, this.projectVersion!.versionNumber, audioFile)
					.subscribe(
						(event) => {
							switch (event.type) {
								case HttpEventType.UploadProgress:
									banner.progress = event.loaded / event.total!;
									if (!banner.text)
										banner.text = this.translationService.getTranslation(
											'_topBanner.uploadProjectAudioFile.uploading.text',
											{ fileName: audioFile.name },
										);
									break;

								case HttpEventType.Response:
									this.topBannerService.doneBanner(banner);
									this.topBannerService.removeBanner(banner);
									this.toastr.success(
										this.translationService.getTranslation('_toastr.uploadProjectAudioFile.success.message', {
											fileName: audioFile.name,
										}),
										this.translationService.getTranslation('_toastr.uploadProjectAudioFile.success.title'),
									);
									break;
							}

							if (event.type === HttpEventType.Response) {
								console.info('File uploaded:', event.body);
							}
						},
						(error) => {
							console.error('Error uploading file:', error);
							banner.icon = 'triangle-exclamation';
							banner.title = this.translationService.getTranslation('_topBanner.uploadProjectAudioFile.error.title');
							banner.text = this.translationService.getTranslation('_topBanner.uploadProjectAudioFile.error.text', {
								fileName: audioFile.name,
							});
							banner.progress = undefined;
							this.toastr.error(
								this.translationService.getTranslation('_toastr.uploadProjectAudioFile.error.message', {
									fileName: audioFile.name,
								}),
								this.translationService.getTranslation('_toastr.uploadProjectAudioFile.error.title'),
							);
							setTimeout(() => {
								this.topBannerService.removeBanner(banner);
							}, 1e4);
						},
					);
			}
		});
	}

	@HostListener('document:keydown.enter', ['$event'])
	protected onKeyEnter(event: KeyboardEvent) {
		if (this.isValidKeypress(event)) this.waveformPlayer.globalAudioPlayer?.stop();
	}

	@HostListener('document:keydown.m', ['$event'])
	protected onKeyEsc(event: KeyboardEvent) {
		if (this.isValidKeypress(event)) {
			this.markerTextInput?.nativeElement.blur();
			this.addMarker().then();
			this.markerTextInput?.nativeElement.focus();
			event.preventDefault();
		}
	}

	private updateMarker() {
		if (this.waveformPlayer?.peaks) {
			this.waveformPlayer.peaks.points.removeAll();
			for (const marker of this.staticMarker) {
				this.waveformPlayer.peaks.points.add({
					time: marker.start,
					color: '#' + marker.color,
				});
			}
			for (const marker of this.waveformPlayerMarkerService.getMarker(this.waveformPlayer.projectVersion!.id!))
				if (marker.id !== undefined && !this.waveformPlayer.peaks.points.getPoint(marker.id))
					this.waveformPlayer.peaks.points.add({
						id: marker.id,
						time: marker.start,
						color: '#' + marker.color,
					});
		}
	}

	private isValidKeypress(event: KeyboardEvent): boolean {
		return event.target === document.body;
	}

	protected async addMarker() {
		if (this.waveformPlayer?.peaks && this.projectVersion) {
			const marker = this.waveformPlayerMarkerService.addMarker(
				this.projectVersion.id!,
				this.waveformPlayer.player.currentTime,
			);
			this.markerChipListComponent.selectMarker(marker);
			this.markerTextInput!.nativeElement.value = this.markerChipListComponent.selectedMarker!.text || '';
			await this.blinkMarkerButton(marker.color);
		}
	}

	private async blinkMarkerButton(color: string) {
		const _color = (color.startsWith('#') ? '' : '#') + color;

		await Promise.all(
			this.markerButtons.map(
				({ nativeElement }) =>
					new Promise<void>(async (resolve) => {
						nativeElement.classList.remove('fading');
						await new Promise<void>((resolve) => requestAnimationFrame(() => resolve()));

						nativeElement.style.color = _color;
						nativeElement.style.textShadow = '0 0 18px ' + _color;
						await new Promise<void>((resolve) => requestAnimationFrame(() => resolve()));

						nativeElement.classList.add('fading');
						nativeElement.style.color = '';
						nativeElement.style.textShadow = '';
						await new Promise<void>((resolve) => requestAnimationFrame(() => resolve()));

						resolve();
					}),
			),
		);
	}

	protected markerActionClick(clickAction: MarkerListMarkerClickInterface) {
		switch (clickAction.action.name) {
			case 'DELETE':
				this.deleteMarker(clickAction.marker);
				break;
			case 'ADD_TO_CHECKLIST':
				this.addMarkerToChecklistClick(clickAction.marker);
				break;
		}
	}

	protected addMarkerToChecklistClick(marker: MarkerListMarkerInterface[]) {
		this.addMarkerToChecklist$.emit(marker);
		this.markerChipListComponent.closeMarkerDropdown();
	}

	deleteMarker(marker: MarkerListMarkerInterface[]) {
		for (const _marker of marker) this.waveformPlayerMarkerService.removeMarker(_marker);
		this.markerChipListComponent.marker = this.waveformPlayerMarkerService.marker;
		this.markerChipListComponent.selectMarker(this.markerChipListComponent.marker[0]);
	}

	downloadAudio(projectVersion: ProjectVersionInterface) {
		const banner = this.topBannerService.addBanner({
			icon: 'file-arrow-down',
			title: this.translationService.getTranslation('_topBanner.downloadAudioFile.preparing.title'),
			text: this.translationService.getTranslation('_topBanner.downloadAudioFile.preparing.text'),
			progress: 0,
		});

		this.projectService.downloadAudioFile(projectVersion).then((result) => {
			if (result) {
				banner.title = this.translationService.getTranslation('_topBanner.downloadAudioFile.downloading.title');
				banner.text = this.translationService.getTranslation('_topBanner.downloadAudioFile.downloading.text', {
					fileName: result.name,
				});

				const subscription = result.download.subscribe(
					({ event }) => {
						if (event.type === HttpEventType.DownloadProgress) banner.progress = event.loaded / event.total!;
					},
					(error) => {
						subscription.unsubscribe();
						console.error('Error downloading audio file:', error);
						banner.icon = 'triangle-exclamation';
						banner.title = this.translationService.getTranslation('_topBanner.downloadAudioFile.error.title');
						banner.text = this.translationService.getTranslation('_topBanner.downloadAudioFile.error.text');
						banner.progress = undefined;
						setTimeout(() => {
							this.topBannerService.removeBanner(banner);
						}, 1e4);
					},
					() => {
						this.topBannerService.removeBanner(banner);
					},
				);
			} else {
				console.error(
					'Error downloading audio file:',
					new Error('empty result form ProjectService.downloadAudioFile(...)'),
				);
				banner.icon = 'triangle-exclamation';
				banner.title = this.translationService.getTranslation('_topBanner.downloadAudioFile.error.title');
				banner.text = this.translationService.getTranslation('_topBanner.downloadAudioFile.error.text');
				banner.progress = undefined;
				setTimeout(() => {
					this.topBannerService.removeBanner(banner);
				}, 1e4);
			}
		});
	}
}
