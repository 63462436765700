<mat-dialog-content class="p-4 pt-0">
  <div class="w-100 text-center">

    <div class="py-1">
      <h2 class="pb-0 user-select-none" mat-dialog-title>
        {{ 'newProjectVersionModal.header' | translate }}
      </h2>
    </div>

    <div class="row w-100 mx-auto">
      <div class="col-12 mt-1">
        <h4 class="ps-1 mb-2 text-dark user-select-none">
          {{ 'newProjectVersionModal.uploadFile' | translate }}
        </h4>
        <input #audioFileInput
               type="file"
               accept=".wav"
               class="form-control">
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end" class="p-4 pt-3">
  <div class="row w-100 mx-auto mb-1">
    <div class="col-12 d-flex align-items-center justify-content-between">
      <button mat-button
              class="btn bg-white text-black border border-1 border-black"
              [mat-dialog-close]="true"
      >{{ 'newProjectVersionModal.nav.cancel' | translate }}
      </button>
      <button mat-button
              class="btn bg-white text-black border border-1 border-black"
              (click)="submit()"
      >{{ 'newProjectVersionModal.nav.submit' | translate }}
      </button>
    </div>
  </div>
</mat-dialog-actions>