<div *ngIf="userExists">
  <app-song-card-slider
    [projects]="projects"
    [loading]="loading"
    [scrollAmount]="songCardScrollAmount"
    [showEmpty]="_songCardShowEmpty"
    (emptyClick)="songCardEmptyClick.emit($event)"
  />
  <app-all-projects-table
    [projects]="projects"
    [loading]="loading"
  />
</div>
<div *ngIf="!userExists" class="position-fixed overlay d-flex justify-content-center align-items-center">
  <span class="h4 text-muted user-select-none mb-5 fw-light">
    {{ 'dashboard.body.userNotExists' | translate }}
  </span>
</div>