import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Notification } from '../../../../interfaces/notification.interface';

@Component({
	selector: 'app-notification-template-user-invited-to-project',
	templateUrl: './notification-template-user-invited-to-project.component.html',
})
export class NotificationTemplateUserInvitedToProjectComponent {
	@Input() notification!: Notification;

	constructor(private readonly router: Router) {}

	navigateToProject() {
		this.router.navigate(['/project/' + this.notification.params['projectId']]).then();
	}
}
