import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { getApiUrl } from '../../config';
import { Login as LoginInterface } from '../interfaces/action/login.interface';
import { AuthLogin as AuthLoginInterface } from '../interfaces/api/authLogin.interface';
import { CookieService } from './cookie.service';
import { UserInfoService } from './user-info.service';

@Injectable({
	providedIn: 'root',
})
export class LoginService {
	private readonly localStorage: Storage = localStorage;

	constructor(
		private readonly http: HttpClient,
		private readonly router: Router,
		private readonly cookieService: CookieService,
		private readonly userInfoService: UserInfoService,
	) {}

	login(loginData: LoginInterface) {
		return this.http.post<AuthLoginInterface>(getApiUrl.apiUrl + '/auth/login', loginData).pipe(
			catchError((error) => {
				console.error('Login error:', error);
				return throwError(error);
			}),
		);
	}

	logout() {
		this.cookieService.deleteCookie('session', true);
		this.localStorage.removeItem('session');
		this.userInfoService.lastUserInfo = undefined;
		this.router.navigate(['/login']).then();
	}
}
