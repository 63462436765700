<div class="position-absolute overlay">
  <div class="container">
    <div class="reset-password-container body-height row justify-content-center align-items-sm-center pb-sm-5">
      <div class="container-x bg-white rounded px-5 py-4 col-md-6 col-lg-5 col-xl-4 box-shadow mb-sm-5">

        <div *ngIf="status === Status.FormEmail">
          <div class="row mb-3">
            <div class="col text-center">
              <h2 class="mb-0">{{ 'resetPassword.form.email.header' | translate }}</h2>
            </div>
          </div>
          <div class="row mb-3 mx-auto">
            <div class="col">
              <input [(ngModel)]="email"
                     #emailInput
                     type="email"
                     class="mx-auto form-control mw-100 custom-width custom-height"
                     [placeholder]="'resetPassword.form.email.placeholder.email' | translate"
                     (keyup)="detectChanges()"
                     (keyup.enter)="requestPasswordReset()"
                     autocomplete="email">
            </div>
          </div>
          <div class="row mb-3 mx-auto">
            <div class="col text-center">
              <button class="btn btn-dark btn-disable-smooth btn-block mw-100 custom-width custom-height"
                      [disabled]="!emailInput.value"
                      (click)="requestPasswordReset()"
              >
                {{ 'resetPassword.form.email.submit' | translate }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="text-center mb-0">
                <a routerLink="/login">{{ 'resetPassword.form.email.link.login' | translate }}</a>
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="status === Status.FormPassword">
          <div class="row mb-3">
            <div class="col text-center">
              <h2 class="mb-0">{{ 'resetPassword.form.password.header' | translate }}</h2>
            </div>
          </div>
          <div class="row mb-3 mx-auto">
            <div class="col">
              <input [(ngModel)]="password"
                     #passwordInput
                     type="password"
                     class="mx-auto form-control mw-100 custom-width custom-height"
                     [placeholder]="'resetPassword.form.password.placeholder.password' | translate"
                     (keyup)="detectChanges()"
                     autocomplete="off">
            </div>
          </div>
          <div class="row mb-3 mx-auto">
            <div class="col">
              <input #passwordRetypeInput
                     type="password"
                     class="mx-auto form-control mw-100 custom-width custom-height"
                     [placeholder]="'resetPassword.form.password.placeholder.passwordRetype' | translate"
                     (keyup)="detectChanges()"
                     autocomplete="off">
            </div>
          </div>
          <div class="row mb-3 mx-auto">
            <div class="col text-center">
              <button class="btn btn-dark btn-disable-smooth btn-block mw-100 custom-width custom-height"
                      [disabled]="!passwordInput.value || !passwordRetypeInput.value || passwordInput.value !== passwordRetypeInput.value"
                      (click)="resetPassword()"
              >
                {{ 'resetPassword.form.password.submit' | translate }}
              </button>
            </div>
          </div>
        </div>

        <div class="request-send-success text-center pt-2"
             [ngClass]="'status-'+(StatusSuccess.includes(status) ? 'success' : 'error')"
             *ngIf="!StatusLayout.includes(status)"
        >
          <div class="h4 d-flex flex-row justify-content-center align-items-center">
            <div class="icon rounded-circle d-flex justify-content-center align-items-center me-2" [innerHtml]="StatusSuccess.includes(status) ? '&#x2714;' : '&#x2715;'"></div>
            <span *ngIf="status === Status.RequestSuccess">{{ 'resetPassword.status.success.header' | translate }}</span>
            <span *ngIf="status === Status.RequestErrorUser">{{ 'resetPassword.status.errorUser.header' | translate }}</span>
            <span *ngIf="status === Status.RequestErrorUnknown">{{ 'resetPassword.status.errorUnknown.header' | translate }}</span>
            <span *ngIf="status === Status.KeyInvalid">{{ 'resetPassword.status.keyInvalid.header' | translate }}</span>
            <span *ngIf="status === Status.ResetSuccess">{{ 'resetPassword.status.resetSuccess.header' | translate }}</span>
            <span *ngIf="status === Status.ResetErrorKey">{{ 'resetPassword.status.resetErrorKey.header' | translate }}</span>
            <span *ngIf="status === Status.ResetErrorUnknown">{{ 'resetPassword.status.resetErrorUnknown.header' | translate }}</span>
            </div>
          <p class="mt-3">
            <span *ngIf="status === Status.RequestSuccess" [innerHtml]="'resetPassword.status.success.text' | translate: { email }"></span>
            <span *ngIf="status === Status.RequestErrorUser" [innerHtml]="'resetPassword.status.errorUser.text' | translate: { email }"></span>
            <span *ngIf="status === Status.RequestErrorUnknown" [innerHtml]="'resetPassword.status.errorUnknown.text' | translate: { email }"></span>
            <span *ngIf="status === Status.KeyInvalid" [innerHtml]="'resetPassword.status.keyInvalid.text' | translate: { email }"></span>
            <span *ngIf="status === Status.ResetSuccess" [innerHtml]="'resetPassword.status.resetSuccess.text' | translate: { email }"></span>
            <span *ngIf="status === Status.ResetErrorKey" [innerHtml]="'resetPassword.status.resetErrorKey.text' | translate: { email }"></span>
            <span *ngIf="status === Status.ResetErrorUnknown" [innerHtml]="'resetPassword.status.resetErrorUnknown.text' | translate: { email }"></span>
          </p>
          <a *ngIf="status === Status.RequestSuccess" routerLink="/login">{{ 'resetPassword.status.success.link' | translate }}</a>
          <a *ngIf="status === Status.RequestErrorUser" routerLink="/reset-password" (click)="status = Status.FormEmail">{{ 'resetPassword.status.errorUser.link' | translate }}</a>
          <a *ngIf="status === Status.RequestErrorUnknown" routerLink="/reset-password" (click)="status = Status.FormPassword">{{ 'resetPassword.status.errorUnknown.link' | translate }}</a>
          <a *ngIf="status === Status.KeyInvalid" routerLink="/login">{{ 'resetPassword.status.keyInvalid.link' | translate }}</a>
          <a *ngIf="status === Status.ResetSuccess" routerLink="/login">{{ 'resetPassword.status.resetSuccess.link' | translate }}</a>
          <a *ngIf="status === Status.ResetErrorKey" routerLink="/login">{{ 'resetPassword.status.resetErrorKey.link' | translate }}</a>
          <a *ngIf="status === Status.ResetErrorUnknown" routerLink="/login">{{ 'resetPassword.status.resetErrorUnknown.link' | translate }}</a>
        </div>

      </div>
    </div>
  </div>
</div>