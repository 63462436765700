export enum ToneOptions {
	C = 'C',
	D = 'D',
	E = 'E',
	F = 'F',
	G = 'G',
	A = 'A',
	H = 'H',
}

export enum SharpFlat {
	None = '',
	Sharp = '#',
	Flat = 'b',
}

export enum Vibe {
	Major = 'Major',
	Minor = 'Minor',
	Dorian = 'Dorian',
	Mixolydian = 'Mixolydian',
	Phrygian = 'Phrygian',
	Locrian = 'Locrian',
	Blues = 'Blues',
	Pentatonic = 'Pentatonic',
	Lydian = 'Lydian',
	Aeolian = 'Aeolian',
	HarmonicMinor = 'Harmonic Minor',
	MelodicMinor = 'Melodic Minor',
	WholeTone = 'Whole Tone',
	Octatonic = 'Octatonic',
	Ionian = 'Ionian',
	HungarianMinor = 'Hungarian Minor',
	NeapolitanMajor = 'Neapolitan Major',
	NeapolitanMinor = 'Neapolitan Minor',
	Enigmatic = 'Enigmatic',
	Spanish = 'Spanish',
	Prometheus = 'Prometheus',
	PrometheusNeapolitan = 'Prometheus Neapolitan',
	PrometheusSpanish = 'Prometheus Spanish',
	DoubleHarmonicMajor = 'Double Harmonic Major',
	DoubleHarmonicMinor = 'Double Harmonic Minor',
	Persian = 'Persian',
	Oriental = 'Oriental',
	LeadingWholeTone = 'Leading Whole Tone',
	LydianAugmented = 'Lydian Augmented',
	LydianMinor = 'Lydian Minor',
	FiveTone = 'Five-Tone',
	SixTone = 'Six-Tone',
	EightToneSpanish = 'Eight-Tone Spanish',
	LeadingWholeToneInverse = 'Leading Whole Tone Inverse',
	LydianDiminished = 'Lydian Diminished',
	NineTone = 'Nine-Tone',
	Arabian = 'Arabian',
	Balinese = 'Balinese',
	Byzantine = 'Byzantine',
	Chinese = 'Chinese',
	Egyptian = 'Egyptian',
	Ethiopian = 'Ethiopian',
	HungarianMajor = 'Hungarian Major',
	Indian = 'Indian',
	Japanese = 'Japanese',
	OrientalA = 'Oriental A',
	OrientalB = 'Oriental B',
	RomanianMinor = 'Romanian Minor',
	Scottish = 'Scottish',
	Thai = 'Thai',
	UkrainianDorian = 'Ukrainian Dorian',
	Vietnamese = 'Vietnamese',
	Tritone = 'Tritone',
	TritoneInverse = 'Tritone Inverse',
}
