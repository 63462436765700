import { Component, Input } from '@angular/core';
import { Notification } from '../../../../interfaces/notification.interface';

@Component({
	selector: 'app-notification-template-user-removed-from-project',
	templateUrl: './notification-template-user-removed-from-project.component.html',
})
export class NotificationTemplateUserRemovedFromProjectComponent {
	@Input() notification!: Notification;
}
