import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { CookieService } from './services/cookie.service';
import { FaviconService } from './services/favicon.service';
import { UserInfoService } from './services/user-info.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
	isLite: boolean = true;

	isOnHome: boolean = false;

	constructor(
		private readonly router: Router,
		private readonly renderer: Renderer2,
		private readonly faviconService: FaviconService,
		private readonly cookieService: CookieService,
		private readonly userInfoService: UserInfoService,
	) {}

	ngOnInit() {
		this.faviconService.startFaviconUpdateInterval(this.renderer);

		this.router.events.subscribe((event) => {
			const url = (event as { url: string }).url;
			if (url) this.isOnHome = url.startsWith('/dashboard');

			if (!this.cookieService.getCookie('session')) this.userInfoService.lastUserInfo = undefined;

			if (
				!this.userInfoService.lastUserInfo &&
				event instanceof NavigationStart &&
				this.cookieService.getCookie('session')
			)
				this.userInfoService.getUserInfo();
		});

		if (this.cookieService.getCookie('session')) this.userInfoService.getUserInfo();
	}
}
