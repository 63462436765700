<div class="position-absolute overlay">
  <div class="container">
    <div class="login-container body-height row justify-content-center align-items-sm-center pb-sm-5">
      <div class="container-x bg-white rounded px-5 py-4 col-sm-9 col-md-7 col-lg-5 col-xl-4 box-shadow mb-sm-5">
        <div class="row mb-3">
          <div class="col text-center user-select-none">
            <h2>{{ 'login.header' | translate }}</h2>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <input [(ngModel)]="email"
                   #emailInput
                   type="text"
                   class="mx-auto form-control custom-width custom-height mw-100"
                   [placeholder]="'login.placeholder.email' | translate"
                   (keyup.enter)="login()"
                   autocomplete="username">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col d-flex justify-content-center">
            <div class="custom-width custom-height mw-100">
              <input [(ngModel)]="password"
                     #passwordInput
                     [type]="showPassword ? 'text' : 'password'"
                     class="custom-height form-control pe-5"
                     [placeholder]="'login.placeholder.password' | translate"
                     (keyup.enter)="login()"
                     autocomplete="current-password">
              <div class="position-relative custom-height bottom-100 d-flex align-items-center justify-content-end pe-1 pe-none">
                <button mat-icon-button matSuffix type="button" (click)="showPassword = !showPassword" class="pe-auto">
                  <mat-icon>{{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <div class="form-check custom-width p-0 w-100 d-flex align-items-center justify-content-center">
              <mat-checkbox
                id="rememberThisDeviceCheckbox"
                #rememberThisDeviceCheckbox
                [(ngModel)]="rememberThisDevice"
              ></mat-checkbox>
              <label class="form-check-label user-select-none" role="button" for="rememberThisDeviceCheckbox" (click)="rememberThisDeviceCheckbox.toggle()">
                {{ 'login.rememberThisDevice' | translate }}
              </label>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col text-center">
            <button class="btn btn-dark btn-disable-smooth btn-block custom-width custom-height mw-100"
                    [disabled]="!emailInput.value.trim() || !passwordInput.value.trim()"
                    (click)="login()"
            >
              {{ 'login.submit' | translate }}
            </button>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <p class="text-center">
              <a routerLink="/reset-password" (click)="gotoResetPassword()">{{ 'login.link.resetPassword' | translate }}</a>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="text-center"><span class="user-select-none">{{ 'login.link.register.prefix' | translate }} </span><a routerLink="/register" (click)="register()">{{ 'login.link.register.link' | translate }}</a></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>