<div class="d-flex flex-column min-vh-100">
  <app-nav-bar
    [isLite]="isLite"
    [sticky]="false"
    [userHome]="!isOnHome"
    [userSearch]="isOnHome"
  ></app-nav-bar>

  <div class="flex-grow-1">
    <app-body></app-body>
  </div>

  <app-footer></app-footer>
</div>