export const randomColor = [
	'24e888',
	'3c3cff',
	'df3b3b',
	'4b4bff',
	'8eff6b',
	'5c5cff',
	'ff6b6b',
	'7171ff',
	'71ffbb',
	'ff8c71',
	'8c8cff',
	'b5ff3b',
	'8c71ff',
	'ff9393',
	'9393ff',
	'a1ff71',
	'9393ff',
	'ffa171',
	'71ffb5',
	'ffad71',
	'adadff',
	'c3ff71',
	'ad71ff',
	'ffbd71',
	'bd71ff',
	'd9ff71',
	'bd71ff',
	'ffe171',
	'e171ff',
	'ffeb71',
	'eb71ff',
	'fffb71',
	'ff71fb',
	'fff271',
	'71fff9',
	'fff271',
	'71fffc',
	'71fcff',
	'71fffd',
	'71fdff',
	'71fffd',
	'71fdff',
	'71fffd',
	'71fdff',
	'71fffd',
	'71fdff',
	'71fffd',
	'71fdff',
	'71fffd',
	'71fdff',
];
