{{ '_notification.userProjectRoleWasChanged.1' | translate }}
<br>
<a type="button" (click)="navigateToProject()" class="link-dark link-opacity-50-hover link-underline-opacity-0-hover">
  <span class="text-decoration-underline">{{ notification.params['projectName'] }}</span>
  <i class="fa fa-arrow-right ms-1"></i>
</a>
<div>
  (
  {{ ('_notification.userProjectRoleWasChanged.role.'+notification.params['prevRole']) | translate }}
  <i class="fas fa-arrow-right fa-l"></i>
  {{ ('_notification.userProjectRoleWasChanged.role.'+notification.params['role']) | translate }}
  )
</div>