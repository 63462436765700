import { Component, Input } from '@angular/core';
import { Notification } from '../../../interfaces/notification.interface';

@Component({
	selector: 'app-notification-template-loader',
	templateUrl: './notification-template-loader.component.html',
})
export class NotificationTemplateLoaderComponent {
	@Input() notification!: Notification;

	isTemplate(templateId: number) {
		return this.notification.notificationTemplateId === templateId;
	}
}
