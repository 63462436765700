<div class="container footer-helper pt-2 mt-5 mt-md-4">
  <footer class="footer mt-auto">
    <ul class="nav justify-content-center border-bottom pb-3 mb-3">
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">{{ 'footer.entries.features' | translate }}</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">{{ 'footer.entries.pricing' | translate }}</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">{{ 'footer.entries.faqs' | translate }}</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">{{ 'footer.entries.about' | translate }}</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">{{ 'footer.entries.imprint' | translate }}</a></li>
    </ul>
    <p class="text-center text-muted">{{ 'footer.company.name' | translate }}</p>
  </footer>
  <app-global-audio-player-space></app-global-audio-player-space>
</div>