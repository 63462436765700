import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../../services/login.service';
import { UserInfoService } from '../../../../services/user-info.service';

@Component({
	selector: 'app-user-dropdown-content',
	templateUrl: './user-dropdown-content.component.html',
	styleUrls: ['./user-dropdown-content.component.scss'],
})
export class UserDropdownContentComponent {
	constructor(
		private readonly router: Router,
		private readonly loginService: LoginService,
		protected readonly userInfoService: UserInfoService,
	) {}

	protected logout() {
		this.loginService.logout();
	}

	userSettings() {
		this.router.navigate(['/user-settings']).then();
	}
}
